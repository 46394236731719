export class GenericResponse<T> {
  private _success: boolean;
  private _payload: T;


  constructor(success: boolean, payload: T) {
    this._success = success;
    this._payload = payload;
  }

  get success(): boolean {
    return this._success;
  }

  set success(value: boolean) {
    this._success = value;
  }

  get payload(): T {
    return this._payload;
  }

  set payload(value: T) {
    this._payload = value;
  }
}
