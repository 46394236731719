export class ChangePasswordDto {
  private username: string;
  private account: string;
  private oldPassword: string;
  private password: string;


  constructor(username: string, account: string, oldPassword: string, password: string) {
    this.username = username;
    this.account = account;
    this.oldPassword = oldPassword;
    this.password = password;
  }
}
